import { apiKey } from "../../../weather-api/api-key";

export async function fetchWeatherData(gps: number[]) {
  const url = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${gps[0]},${gps[1]}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Erreur lors de la récupération des données météo");
  }
  const data = await response.json();
  return {
    windDir: data.current.wind_dir,
    windSpeed: data.current.wind_kph,
  };
}
