import React from "react";
import {
  windIconE,
  windIconN,
  windIconNE,
  windIconNW,
  windIconS,
  windIconSE,
  windIconSW,
  windIconW,
} from "../../design/icons/index";

interface WindIconProps {
  direction: string;
}

const WindIcon: React.FC<WindIconProps> = ({ direction }) => {
  const styles = { width: "25px", height: "25px" };
  const icons: { [key: string]: string } = {
    N: windIconN,
    NE: windIconNE,
    E: windIconE,
    SE: windIconSE,
    S: windIconS,
    SW: windIconSW,
    W: windIconW,
    NW: windIconNW,
  };

  const iconSrc = icons[direction] || null;

  return iconSrc ? <img src={iconSrc} style={styles} alt={direction} /> : null;
};

export default WindIcon;
