import React, { useEffect, useState } from "react";
import WindIcon from "./WindIcon";
import { fetchWeatherData } from "./utils/weatherUtils";
import { getWindColor } from "./utils/windUtils";
import { isMobile } from "react-device-detect";
import windIcon from "../../design/icons/vent_noir.png";
import { yeuGPS } from "../../gps-data/gps";

const GlobalData: React.FC = () => {
  const [showGlobalData, setShowGlobalData] = useState(false);
  const [windDirection, setWindDirection] = useState<string>("");
  const [windKPH, setWindKPH] = useState<number | null>(null);

  useEffect(() => {
    fetchWeatherData(yeuGPS)
      .then(({ windDir, windSpeed }) => {
        setWindDirection(windDir);
        setWindKPH(windSpeed);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données météo :",
          error
        );
      });
  }, []);

  return (
    <div
      className={`
        ${
          isMobile
            ? "global-data-card-mobile-open"
            : "global-data-card-desktop-open"
        }`}
      onClick={() => setShowGlobalData(!showGlobalData)}
    >
      <div className="title-wind">
        <img
          src={windIcon}
          className="global-data-content"
          style={{ width: "40px", height: "40px" }}
        />
        <div className="wind-kph">
          <p className="wind-kph-value">{windKPH ?? "N/A"}</p>
          <p className="wind-kph-unity">km/h</p>
        </div>
      </div>
      <div className="content-wind">
        <p>Vent</p>
        <div
          className="color-wind"
          style={{ backgroundColor: getWindColor(windKPH) }}
        />
        <WindIcon direction={windDirection} />
        <p>{windDirection || "Inconnu"}</p>
      </div>
    </div>
  );
};

export default GlobalData;
