import React, { useRef, useState, useCallback, useMemo } from "react";
import ReactMapGL, { MapRef } from "react-map-gl";
import "./Map.css";
import BeachMarker from "../components/beach/BeachMarker";
import RecenterButton from "../components/design-system/RecenterButton";
import { Beach } from "../types/Beach";
import { beaches } from "../components/beach/BeachConfig";

interface MapProps {
  sortedBeaches: Beach[];
  displayBeaches: boolean;
  windSortDisplayBeaches: Beach | undefined;
  setShowLandingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

// Centralized configuration for Mapbox
const MAPBOX_CONFIG = {
  TOKEN:
    "pk.eyJ1Ijoiem9lYm9ybnkiLCJhIjoiY2xrZnZnOHJoMG14cTNnbzU5bmU4YnJwYSJ9.mQVtY5FXmAbfBbZ_rUmD2w",
  STYLE: "mapbox://styles/zoeborny/clqxtwnki014s01nwgb5l1kf3",
};

const INITIAL_CENTER = { lat: 46.71, lng: -2.34, zoom: 12.45 };

const Map = ({
  sortedBeaches,
  displayBeaches,
  windSortDisplayBeaches,
  setShowLandingPage,
}: MapProps) => {
  const mapRef = useRef<MapRef>(null);
  const [selectedBeach, setSelectedBeach] = useState<Beach | null>(null);
  const [showRecenterButton, setShowRecenterButton] = useState<boolean>(false);

  const handleMarkerClick = useCallback((beach: Beach) => {
    setSelectedBeach(beach);
  }, []);

  const goToInitialLocation = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [INITIAL_CENTER.lng, INITIAL_CENTER.lat],
        zoom: INITIAL_CENTER.zoom,
      });
    }
  }, []);

  const filteredBeaches = useMemo(
    () => sortedBeaches.filter((beach) => beach.compatibilityPercentage > 0),
    [sortedBeaches]
  );

  const renderMarkers = useCallback(() => {
    if (filteredBeaches.length > 0) {
      return filteredBeaches.map((beach) => (
        <BeachMarker
          key={beach.name}
          beach={beach}
          onClick={() => handleMarkerClick(beach)}
          isSelected={selectedBeach === beach}
        />
      ));
    }

    if (displayBeaches) {
      return beaches.map((beach) => (
        <BeachMarker
          key={beach.name}
          beach={beach}
          onClick={() => handleMarkerClick(beach)}
          isSelected={selectedBeach === beach}
          displayBeaches={displayBeaches}
        />
      ));
    }

    if (windSortDisplayBeaches) {
      return (
        <BeachMarker
          key={windSortDisplayBeaches.name}
          beach={windSortDisplayBeaches}
          onClick={() => handleMarkerClick(windSortDisplayBeaches)}
          isSelected
          displayBeaches={displayBeaches}
        />
      );
    }

    return null;
  }, [filteredBeaches, displayBeaches, windSortDisplayBeaches, selectedBeach]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactMapGL
        ref={mapRef}
        initialViewState={{
          latitude: INITIAL_CENTER.lat,
          longitude: INITIAL_CENTER.lng,
          zoom: INITIAL_CENTER.zoom,
        }}
        mapboxAccessToken={MAPBOX_CONFIG.TOKEN}
        mapStyle={MAPBOX_CONFIG.STYLE}
        attributionControl={false}
        onDrag={() => setShowRecenterButton(true)}
        onLoad={() => setShowLandingPage(false)}
      >
        {renderMarkers()}
        <RecenterButton
          onClick={goToInitialLocation}
          show={showRecenterButton}
        />
      </ReactMapGL>
    </div>
  );
};

export default Map;
